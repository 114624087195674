import {useEffect, useState} from 'react'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {UsersListPagination} from '../components/pagination/UsersListPagination'
import {KTCardBody, KTIcon} from '../../../../../../_metronic/helpers'
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../auth/core/Auth';
import { RolesEnum } from '../../../../auth/RolesEnum';
import { assignPsychologistToPatient, getPsychologists, unassignPsychologistToPatient } from '../../../../auth/core/_requests';
import { aU } from '@fullcalendar/core/internal-common';
import { UserModel } from '../../../../auth';

const UsersTable = () => {
  const isLoading = false
  const {currentUser, setCurrentUser, auth} = useAuth()

  const [psychologists, setPsychologists] = useState<Array<unknown>>([]);

  useEffect(() => {   
    fetchPsychologists()
  }, []);

  const fetchPsychologists = async () => {
    const psychologistsRes = await getPsychologists(auth?.token!)
    setPsychologists(psychologistsRes.data);
  }

  const setUser = async (res) => {
    currentUser!.psychologists! = res.psychologists
    setCurrentUser(currentUser!)
  }
  

  const assignPsychologist = async (psychologistId) => {
    const assignPsychologistToPatientRes = await assignPsychologistToPatient(auth?.token!, psychologistId)
    console.log("assignPsychologistToPatient", assignPsychologistToPatientRes)
    await fetchPsychologists()
    await setUser(assignPsychologistToPatientRes.data)
  }

  const unassignPsychologist = async (psychologistId) => {
    const unassignPsychologistToPatientRes = await unassignPsychologistToPatient(auth?.token!, psychologistId)
    console.log("UNassignPsychologistToPatient", unassignPsychologistToPatientRes)
    await fetchPsychologists()
    await setUser(unassignPsychologistToPatientRes.data)
  }

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>
                Име
              </th>
              <th>
                Email
              </th>
              <th className='text-end'>
                Действия
              </th>
              {/* {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))} */}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>                      
            {psychologists!.length > 0 ? (
              psychologists.map((psychologist: any, index) => (
                <tr key={index}>
                  <td>
                    <a> { psychologist.profile.firstName + " " + psychologist.profile.lastName }</a>
                  </td>
                  <td>
                    <a>{ psychologist.email }</a>
                  </td>
                  <td className='text-end'>
                    { 
                      currentUser?.roles?.includes(RolesEnum.ROLE_ADMIN) 
                      ?
                        <Link
                          to={`${psychologist.md5Id}`}
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                        >
                          <KTIcon iconName='people' className='fs-2' />
                        </Link>
                      :
                      
                        <>  
                        {
                          currentUser?.roles?.includes(RolesEnum.ROLE_PATIENT) &&
                          currentUser!.psychologists! && currentUser!.psychologists!.length > 0 && currentUser!.psychologists!.some(psy => psy['md5Id'] === psychologist.md5Id) ?
                            <Link
                              to=""
                              onClick={() => unassignPsychologist(psychologist.md5Id)}
                              className='btn btn-sm btn-icon btn-bg-danger btn-color-white me-1'
                            >
                              <KTIcon iconName='minus' className='fs-2' />
                            </Link>
                          :
                          <Link
                              to=""
                              onClick={() => assignPsychologist(psychologist.md5Id)}
                              className='btn btn-sm btn-icon btn-bg-success btn-color-white me-1'
                            >
                              <KTIcon iconName='plus' className='fs-2' />
                            </Link>
                        }                          
                          <Link
                            to={`${psychologist.md5Id}/calendar`}
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='calendar' className='fs-2' />
                          </Link>
                        </>
                    }
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Няма намерени резултати
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <UsersListPagination /> */}
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export {UsersTable}
