import { initializeApp } from "firebase/app";
import { getDatabase, orderByChild, equalTo, ref, set } from "firebase/database";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD0PVTyr9USiLfH0SHn9p29tYP8EwkKU6E",
    authDomain: "webrtc-704a6.firebaseapp.com",
    databaseURL: "https://webrtc-704a6-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "webrtc-704a6",
    storageBucket: "webrtc-704a6.appspot.com",
    messagingSenderId: "608976290925",
    appId: "1:608976290925:web:a3efa8d151ead4f3772b64",
    measurementId: "G-FMQQ9EVL1G"
};

let app = initializeApp(firebaseConfig);
let dbase = getDatabase(app);
const auth = getAuth(app);

export const db = dbase;
export { dbase, auth, orderByChild, equalTo, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword };
