import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { auth, createUserWithEmailAndPassword, onAuthStateChanged} from '../../../../firebase';

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/profile`
export const GET_PSYCHOLOGISTS_URL = `${API_URL}/api/psychologists/list`
export const GET_PATIENTS_URL = `${API_URL}/api/psychologist/patients/list`
export const GET_EVENTS_URL = `${API_URL}/api/events/list`
export const GET_EVENTS_BY_PSYCHOLOGIST_URL = `${API_URL}/api/events/`
export const ASSIGN_PSYCHOLOGIST_TO_PATIENT_URL = `${API_URL}/api/patients/psychologist/`
export const NOTIFICATIONS_URL = `${API_URL}/api/user/notifications/`
export const LOGIN_URL = `${API_URL}/auth/token`
export const REGISTER_PATIENT_URL = `${API_URL}/api/patient/registration`
export const REGISTER_PSYCHOLOGIST_URL = `${API_URL}/api/psychologist/registration`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const PATIENT_NOTES_URL = `${API_URL}/api/patient`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  passwordConfirm: string
) {
  return axios.post(REGISTER_PATIENT_URL, {
    email,
    firstName: firstName,
    lastName: lastName,
    password,
    passwordConfirm,
  })
}

export function registerPsychologist(
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  passwordConfirm: string,
  certificateNumber: string
) {
  return axios.post(REGISTER_PSYCHOLOGIST_URL, {
    email,
    firstName: firstName,
    lastName: lastName,
    password,
    passwordConfirm,
    certificateNumber,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    token
  })
}

export function getPsychologists(token: string) {
  return axios.get(GET_PSYCHOLOGISTS_URL, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export function getPatients(token: string) {
  return axios.get(GET_PATIENTS_URL, {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export function getEventsForPsychologist(token: string, psychologistId: string) {
  return axios.get(`${GET_EVENTS_BY_PSYCHOLOGIST_URL}${psychologistId}/list`, {   
    headers: {
      'Authorization': 'Bearer ' + token
    }
  })
}

export function createEventForPsychologist(token: string, psychologistId: string, event) {
  return axios.post(`${GET_EVENTS_BY_PSYCHOLOGIST_URL}${psychologistId}/create`,  
    event, 
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}


export function deleteEventForPsychologist(token: string, eventId) {
  return axios.post(`${GET_EVENTS_BY_PSYCHOLOGIST_URL}delete/${eventId}`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}


export function assignPsychologistToPatient(token: string, psychologistId: string) {
  return axios.post(`${ASSIGN_PSYCHOLOGIST_TO_PATIENT_URL}${psychologistId}/add`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function unassignPsychologistToPatient(token: string, psychologistId: string) {
  return axios.post(`${ASSIGN_PSYCHOLOGIST_TO_PATIENT_URL}${psychologistId}/remove`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function getUserNotifications(token: string) {
  return axios.get(`${NOTIFICATIONS_URL}list`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function deleteUserNotification(token: string, notificationId: string) {
  return axios.post(`${NOTIFICATIONS_URL}delete/${notificationId}`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function getPatientNotes(token: string, patientId: string) {
  return axios.post(`${PATIENT_NOTES_URL}/${patientId}/notes`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function getSinglePatientNote(token: string, noteId: string) {
  return axios.post(`${PATIENT_NOTES_URL}/notes/${noteId}`,  
    { headers: { 'Authorization': 'Bearer ' + token} },
  )
}

export function createPatientNote(token: string, patientId: string, noteText: string) {
  return axios.post(`${PATIENT_NOTES_URL}/${patientId}/notes/create`,  
    {noteText: noteText},
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function updatePatientNote(token: string, patientId: string, noteId: string, noteText: string) {
  return axios.post(`${PATIENT_NOTES_URL}/${patientId}/notes/${noteId}/update`,  
    {noteText: noteText},
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}

export function removePatientNote(token: string, noteId: string) {
  return axios.post(`${PATIENT_NOTES_URL}/notes/${noteId}/remove`,  
    { headers: { 'Authorization': 'Bearer ' + token} },      
  )
}