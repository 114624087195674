/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { EventInput } from '@fullcalendar/core'
import { CalendarEventModal } from '../../../_metronic/partials/modals/CalendarEventModal'
import {useAuth} from '../../modules/auth'
import { RolesEnum } from '../../modules/auth/RolesEnum'
import { useParams } from 'react-router'
import { createEventForPsychologist, deleteEventForPsychologist, getEventsForPsychologist } from '../../modules/auth/core/_requests'

type CustomEventInput = EventInput & {
  roomId?: string;
  timeStart?: number;
  timeEnd?: number;
};

const CalendarPage: FC = () => {
  
  const {psychologistId, patientId} = useParams()
  const [events, setEvents] = useState<CustomEventInput[]>([]);
  const [hoveredEvent, setHoveredEvent] = useState<string | null>(null);
  const {currentUser, auth} = useAuth()

  useEffect(() => {
    console.log("ASASDASD")    
    fetchEvents()
  }, []);

  const fetchEvents = async () => {
    
    const eventsRes = await getEventsForPsychologist(auth?.token! , psychologistId!)
    console.log(eventsRes.data)    
    if (eventsRes.data) {
      const eventsData:CustomEventInput[] = [];
      eventsRes.data.forEach(event => {
        
          const isoTimeStart = event.startTime;
          const isoTimeStartObject = new Date(isoTimeStart);

          const isoTimeEnd = event.endTime;
          const isoTimeEndObject = new Date(isoTimeEnd);

          eventsData.push({
            title: event.title,
            start: isoTimeStartObject,
            end: isoTimeEndObject,
            extendedProps: {
              id: event.id,
              roomId: event.roomId,
            },
          });
        
      });

      setEvents(eventsData);
    }
  }

  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedEvent, setSelectedEvent] = useState<CustomEventInput | null>(null);

  const handleDateClick = (info) => {
    setSelectedDate(info.startStr);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setTimeout(() => {
      setSelectedEvent(null)
    }, 300)
    setIsModalOpen(false);
  };

  const handleDeleteEvent = async (event) => {
  
    if (window.confirm("Наистина ли искате да изтриете срещата!") == true) {
      await deleteEventForPsychologist(auth?.token!, event.extendedProps.id)
      await fetchEvents()
    } else {
      
    } 
  };

  const handleEventAdd = async (event) => {

    if (event.newEventTitle && selectedDate) {

      let dateStart = new Date(selectedDate)
      let timeStart = event.newEventTimeStart.split(":")
      console.log(timeStart)
      dateStart.setHours(timeStart[0])
      dateStart.setMinutes(timeStart[1])

      let dateEnd = new Date(selectedDate)
      let timeEnd = event.newEventTimeEnd.split(":")
      dateEnd.setHours(timeEnd[0])
      dateEnd.setMinutes(timeEnd[1])
      
      const newEvent: CustomEventInput = {
        title: event.newEventTitle,
        start: selectedDate,
        timeStart: dateStart.getTime() / 1000,
        timeEnd: dateEnd.getTime() / 1000,
        roomId: makeid(20),
      };

      writeEventData(newEvent)

      await fetchEvents()
      closeModal();
    }
  };

  const writeEventData = async (newEvent) => {
    if(currentUser?.roles?.includes(RolesEnum.ROLE_PATIENT)){
      await createEventForPsychologist(auth?.token!, psychologistId!, {   
        // patientId: currentUser!.id,   
        title: newEvent.title,
        startTime: newEvent.timeStart,
        endTime: newEvent.timeEnd,
      });
    }else{
      await createEventForPsychologist(auth?.token!, psychologistId!, {   
        patientId: patientId,   
        title: newEvent.title,
        startTime: newEvent.timeStart,
        endTime: newEvent.timeEnd,
      });
    }
    fetchEvents()
  }

  const handleEventClick = (event) => {
    setSelectedEvent(event)
    setIsModalOpen(true)
    // var roomId = info.event.extendedProps.roomId
    // window.open("https://sensus.kynama.com?id=" + roomId, "_blank");   
  };

  return (
  <>
    {/* begin::Row */}
    <CalendarEventModal 
      show={isModalOpen} 
      handleClose={closeModal} 
      selectedEvent={selectedEvent} 
      setEvents={(event) => handleEventAdd(event)} 
    />
    <FullCalendar
        locale={'bg'}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,dayGridDay',
        }}
        buttonText= {{
          today: 'Днес',
          day: 'Ден',
          week:'Седмица',
          month:'Месец'
        }}
        events={events}
        eventContent={(eventInfo) => (
          <div 
            className='d-flex flex-row gap-4 justify-content-between align-items-center px-2 py-2 w-100 bg-primary text-light rounded'
            onMouseEnter={() => setHoveredEvent(eventInfo.event.extendedProps.roomId)}
            onMouseLeave={() => setHoveredEvent(null)}
          >
            <div
                onClick={() => handleEventClick(eventInfo.event)} 
                style={{cursor: 'pointer'}}
                className='d-flex flex-column gap-1 justify-content-between w-100'>
              <div className='d-flex'>
                <b>{eventInfo.event.title}</b>
              </div>
              <div className='d-flex flex-row gap-2'>
                <span><b>{eventInfo.event.start?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b></span>
                <span><b>-</b></span>
                <span><b>{eventInfo.event.end?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</b></span>
              </div>              
            </div>
            {hoveredEvent === eventInfo.event.extendedProps.roomId && (
              <div>
              <a 
                  style={{cursor: 'pointer'}}
                  className='badge bg-white text-light p-1'
                  onClick={() => 
                    handleDeleteEvent(eventInfo.event)
                }>

                  <KTIcon iconName='trash-square' className='text-danger fs-1' />
                </a>
              </div>
            )}
          </div>
        )}
        selectable={true}
        select={handleDateClick}
        // eventClick={handleEventClick}
    />          
  </>)
}

const CalendarWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {
        // currentUser?.roles?.includes(RolesEnum.ROLE_PATIENT) &&
          <CalendarPage />
      }     
    </>
  )
}

export {CalendarWrapper}
