import { RolesEnum } from '../../../../../auth/RolesEnum'
import { useAuth } from '../../../../../auth/core/Auth'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

const UsersListHeader = () => {
  const {selected} = useListView()
  const {currentUser} = useAuth()

  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent />
      {/* begin::Card toolbar */}
      {
        currentUser?.roles?.includes(RolesEnum.ROLE_ADMIN) &&
          <div className='card-toolbar'>
            {/* begin::Group actions */}
            {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
            {/* end::Group actions */}
          </div>
      }
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
