import {useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../helpers'

type Props = {
  show: boolean
  handleClose: () => void
  setEvents: (any) => void
  selectedEvent: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CalendarEventModal = ({show, handleClose, setEvents, selectedEvent}: Props) => {

  const [newEventTitle, setNewEventTitle] = useState('');
  const [newEventTimeStart, setNewEventTimeStart] = useState('');
  const [newEventTimeEnd, setNewEventTimeEnd] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
 
  const handleEventAdd = () => {
    if(newEventTitle != '' && newEventTimeStart != '' && newEventTimeEnd != ''){
      setEvents({newEventTitle, newEventTimeStart, newEventTimeEnd});
      handleClose();
      setNewEventTitle('');     
    }
  };

  const handleJoinMeeting = () => {
    if(selectedEvent.extendedProps.roomId){
       var roomId = selectedEvent.extendedProps.roomId;
      window.open("https://sensus.kynama.com?id=" + roomId, "_blank");   
    }
  };  

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ selectedEvent ? selectedEvent.title : 'Добави среща' } </h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      {
        selectedEvent       
        ?
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='d-flex justify-content-between'>
              <div>
                <h6>Начало: { selectedEvent.start?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }</h6>
                <h6>Край: { selectedEvent.end?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }</h6>
              </div>
              <button className='btn btn-lg btn-primary me-3' onClick={handleJoinMeeting}>Към срещата</button>
            </div>
          </div>
        :    
          <div className='modal-body py-lg-10 px-lg-10'>
            <p><b>Заглавие</b></p>
            <input
              required={true}
              className='form-control form-control-lg form-control-solid'
              type="text"
              placeholder="Въведете заглавие"
              value={newEventTitle}
              onChange={(e) => setNewEventTitle(e.target.value)}
            />
            <br></br>
            <div className='d-flex flex-row justify-content-between gap-5 mb-10'>
              <div className='w-100'>
                <p><b>Начало</b></p>
                <input
                  required={true}
                  className='form-control form-control-lg form-control-solid'
                  type="time"
                  placeholder="Event Time Start"
                  value={newEventTimeStart}
                  onChange={(e) => setNewEventTimeStart(e.target.value)}
                />
              </div>
              <div className='w-100'>
                <p><b>Край</b></p>
                <input
                  required={true}
                  className='form-control form-control-lg form-control-solid'
                  type="time"
                  placeholder="Event Time End"
                  value={newEventTimeEnd}
                  onChange={(e) => setNewEventTimeEnd(e.target.value)}
                />
              </div>
            </div>        
            
            <button className='btn btn-lg btn-light-primary me-3' onClick={handleEventAdd}>Добави</button>
            <button className='btn btn-lg btn-light-primary me-3' onClick={handleClose}>Отказ</button>
          </div>
        }
    </Modal>,
    modalsRoot
  )
}

export {CalendarEventModal}
