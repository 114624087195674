import {KTIcon} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import { PatientNotesModal } from '../../../../../../../_metronic/partials/modals/PatientNotesModal'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

const PatientsDetailsToolbar = (props) => {

  useEffect(() => {
      setIsModalOpen(props.openModal)
  }, [props.openModal])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setTimeout(() => {
      // setSelectedEvent(null)
    }, 300)
    setIsModalOpen(false);
    props.onModalClose()
  };
  
  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}
      <PatientNotesModal 
        show={isModalOpen} 
        handleClose={closeModal} 
        // selectedEvent={selectedEvent} 
        // setEvents={(event) => handleEventAdd(event)} 
      />
      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTIcon iconName='exit-up' className='fs-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={() => setIsModalOpen(true)}>
        <KTIcon iconName='plus' className='fs-2' />
        { 'Добави бележка' }
      </button>
      {/* end::Add user */}
    </div>
  )
}

export {PatientsDetailsToolbar}
