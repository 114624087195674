/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {KTIcon} from '../../helpers'
import { useParams } from 'react-router'
import { db, dbase } from '../../../firebase';
import { set, ref, push, onValue, update } from 'firebase/database';
import { createPatientNote, getSinglePatientNote, updatePatientNote } from '../../../app/modules/auth/core/_requests'
import { useAuth } from '../../../app/modules/auth'

type Props = {
  show: boolean
  handleClose: () => void
  // setEvents: (any) => void
  // selectedEvent: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const PatientNotesModal = ({show, handleClose}: Props) => {
  const {auth} = useAuth()

  const { psychologistId, patientId, noteId } = useParams();

  const [note, setNote] = useState('');
 
  const handleCreateNote = () => {
    if(noteId){
      updateNote()
    }
    else
    {
      createNote()
    }
  };

  const createNote = async () => {
    let notesRes = await createPatientNote(auth?.token!, patientId!, note);
    closeModal()
  }

  const updateNote = async () => {
    let notesRes = await updatePatientNote(auth?.token!, patientId!, noteId!, note);
    closeModal()
  }

  const fetchNote = async () => {
    let notesRes = await getSinglePatientNote(auth?.token!, noteId!);
    setNote(notesRes.data.content);
  }
  useEffect(() => {
    if(noteId){
      fetchNote()
    }
  }, [noteId])

  const closeModal = () => {
    setNote('')
    handleClose()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={closeModal}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ noteId ? 'Редактирай бележка' : 'Добави бележка' } </h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModal}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      {        
          <div className='modal-body py-lg-10 px-lg-10'>
            <p><b>Бележка</b></p>
            <textarea
              required={true}
              className='form-control form-control-lg form-control-solid'            
              placeholder="Съдържание"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />           
            <br></br>
            <button className='btn btn-lg btn-light-primary me-3' onClick={handleCreateNote}>{ noteId ? 'Обнови' : 'Добави' }</button>
            <button className='btn btn-lg btn-light-primary me-3' onClick={closeModal}>Затвори</button>
          </div>
        }
    </Modal>,
    modalsRoot
  )
}

export {PatientNotesModal}
