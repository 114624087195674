import {Link, useParams} from 'react-router-dom'
import { KTCard, KTCardBody, KTIcon } from '../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import { PatientsDetailsHeader } from './users-list/components/header/PatientsDetailsHeader'
import {useNavigate} from 'react-router-dom';
import { getPatientNotes, removePatientNote } from '../../auth/core/_requests';
import { useAuth } from '../../auth';

const PatientDetailedPage = () => {

  const {psychologistId, patientId, noteId} = useParams();
  const [notes, setNotes] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const {auth} = useAuth()
  
  useEffect(() => {
      fetchPatientNotes()    
  }, [])

  const fetchPatientNotes = async () => {
    let notesRes = await getPatientNotes(auth?.token!, patientId!);
    setNotes(notesRes.data)
  }

  const deleteNote = async (noteId: any) => {
    await removePatientNote(auth?.token!, noteId);
    fetchPatientNotes()
  }

  const navigate = useNavigate();

  function onModalClose() {
    if(noteId){
      navigate(-1);
    }
    setOpenModal(false)
    fetchPatientNotes()
  }

  return (
   <>
    <KTCard>       
      <PatientsDetailsHeader openModal={openModal} onModalClose={() => onModalClose()}/>
      <KTCardBody>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                <th>
                  Бележки
                </th>              
                <th className='text-end'>
                  Действия
                </th>
                {/* {headers.map((column: ColumnInstance<User>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))} */}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>                      
              {notes!.length > 0 ? (
                notes.map((note: any, index) => (
                  <tr key={index}>
                    <td>
                        <a>{note.content}</a>
                    </td>                  
                    <td className='text-end'>                     
                      <Link
                        to={`${note.id}/edit`}
                        onClick={() => setOpenModal(!openModal)} //={`notes/${note.id}/edit`}
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                      >
                        <KTIcon iconName='pencil' className='fs-2' />
                      </Link>
                      <a
                        onClick={() => deleteNote(note.id)}
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-2'
                      >
                        <KTIcon iconName='trash' className='fs-2' />
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Няма намерени резултати
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </KTCardBody>
    </KTCard>
   </>
  )
}

export default PatientDetailedPage
