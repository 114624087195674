import { useState } from 'react'
import {useListView} from '../../core/ListViewProvider'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import { PatientsDetailsToolbar } from './PatientsDetailsToolbar'

const PatientsDetailsHeader = (props) => {
  const {selected} = useListView()  

  return (
    <div className='card-header border-0 pt-6'>
      <div className='invisible'>
        <UsersListSearchComponent />
      </div>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping /> : <PatientsDetailsToolbar openModal={props.openModal} onModalClose={props.onModalClose} />}
        {/* end::Group actions */}
      </div>    
      {/* end::Card toolbar */}
    </div>
  )
}

export {PatientsDetailsHeader}